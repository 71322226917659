<template>
  <v-container>
    <v-alert
      v-if="needSetupRepo"
      dense
      border="left"
      colored-border
      type="info"
      elevation="2"
      >
      Repository is not configured. <router-link :to="{name: 'ProjectGitRepository'}">Repository settings</router-link>.
    </v-alert>

    <v-row no-gutters v-if="!showRefs">
      <v-skeleton-loader type="sentences, sentences, sentences" width="50%" />
    </v-row>
    <v-row no-gutters v-else>
      <v-card-title>Branches</v-card-title>
      <v-card-text>
        <v-simple-table dense fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Hash</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="ref in refs.brancesList"
                :key="ref.hash"
                style="cursor: pointer"
              >
                <td>{{ ref.name }}</td>
                <td>{{ ref.hash }}</td>
                <td>
                  <v-btn small text :loading="waitRelease" :disabled="waitRelease" @click="doRelease(ref.hash)">Release</v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

      <v-card-title>Tags</v-card-title>
      <v-card-text>
        <v-simple-table dense fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Hash</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="ref in refs.tagsList"
                :key="ref.hash"
                style="cursor: pointer"
              >
                <td>{{ ref.name }}</td>
                <td>{{ ref.hash }}</td>
                <td>
                  <v-btn small text :loading="waitRelease" :disabled="waitRelease" @click="doRelease(ref.hash)">Release</v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-row>
  </v-container>
</template>

<script>
import projects from "../../services/projects";
import api from "../../api";
export default {
  name: "Release",
  created() {
    this.projectId = this.$route.params.projectId;
    projects
      .getProjectById(this.projectId)
      .then((res) => {
        this.project = res;
        return res;
      })
      .then((res) => {
        if (!res.info.repoUrl) {
          this.refs = { brancesList: [], tagsList: [] };
          return;
        }
        api.project
          .repoInfo({ projectId: this.projectId, repoUrl: res.info.repoUrl })
          .then((res) => {
            this.refs = res;
          });
      });
  },
  methods: {
    doRelease(hash) {
      if (!this.project.info.repoUrl) {
        return;
      }
      this.waitRelease = true
      api.project
        .repoPull({
          projectId: this.projectId,
          repoUrl: this.project.info.repoUrl,
          hash: hash,
        })
        .then((res) => {
          this.$notifInfo(
            "successfully released a new version for the project " +
              this.projectId,
            3000
          );
        }).finally(() => {
          this.waitRelease = false
        });
    },
  },
  computed: {
    showRefs() {
      return (
        this.refs != null && (!!this.refs.brancesList || !!this.refs.tagsList)
      );
    },
    needSetupRepo() {
      if (!this.project) {
        return false
      }
      return this.project.needToSetup.setGitRepo
    }
  },
  components: {},
  data: () => ({
    projectId: null,
    project: null,
    refs: null,
    waitRelease: false,
  }),
};
</script>
